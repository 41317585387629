export const premiumInterval = {
  validate: (value) => {
    let premiumInputFormat = /^(100|(\d{1,2}(\.\d{1,2})?))(\s*,\s*(100|(\d{1,2}(\.\d{1,2})?)))*$/;
    return premiumInputFormat.test(value);
  },
  getMessage: (_) => "El campo debe ser números positivos separados por coma y espacio",
}

export const termDataInterval = {
  getMessage: (_) => "El campo debe ser números enteros positivos separados por coma y espacio",
  validate: (value) => {
    let premiumInputFormat = /^\d+(?:,\s?\d+)*$/;
    return premiumInputFormat.test(value);
  },
}

export const uniqueRates = {
  getMessage: (_) => "La tasa ya existe",
  validate: (value, rates) => {
    const uniqueRates = [...new Set(rates)];

    return !uniqueRates.includes(value)
  },
}
