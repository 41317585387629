import axios from "axios";

let qs = require("qs");
const sprintf = require("sprintf-js").sprintf;

export default class realStateProjectsClient {
  async create({ data } = {}) {
    let url = `${process.env.VUE_APP_REAL_STATE_PROJECTS_BASE_URL}/`;
    return axios({
      url: url,
      method: "post",
      data: data,
      headers: {
        "x-api-key": process.env.VUE_APP_API_KEY,
      },
    });
  }

  async retrieve({ pathParameters, queryStrings } = {}) {
    let url = `${process.env.VUE_APP_REAL_STATE_PROJECTS_BASE_URL}/%(id)s`;
    return axios({
      url: `${sprintf(url, pathParameters)}`,
      method: "get",
      params: { stage: "staging", ...queryStrings },
      paramsSerializer: function(queryStrings) {
        return qs.stringify(queryStrings, { arrayFormat: "repeat" });
      },
    });
  }

  async update({ pathParameters, data } = {}) {
    let url = `${process.env.VUE_APP_REAL_STATE_PROJECTS_BASE_URL}/%(id)s`;
    return axios({
      url: `${sprintf(url, pathParameters)}`,
      method: "put",
      data: data,
      headers: {
        "x-api-key": process.env.VUE_APP_API_KEY,
      },
    });
  }

  async delete({ pathParameters } = {}) {
    let url = `${process.env.VUE_APP_REAL_STATE_PROJECTS_BASE_URL}/%(id)s`;
    return axios({
      url: `${sprintf(url, pathParameters)}`,
      method: "delete",
      headers: {
        "x-api-key": process.env.VUE_APP_API_KEY,
      },
    });
  }

  async list({ queryStrings } = {}) {
    let url = `${process.env.VUE_APP_REAL_STATE_PROJECTS_BASE_URL}`;
    return axios({
      url: url,
      method: "get",
      params: { stage: "staging", ...queryStrings },
      paramsSerializer: function(queryStrings) {
        return qs.stringify(queryStrings, { arrayFormat: "repeat" });
      },
    });
  }

  async listDeletedProjects() {
    let url = `${process.env.VUE_APP_REAL_STATE_PROJECTS_BASE_URL}/deleted-projects`;
    return axios({
      url: url,
      method: "get",
    });
  }

  async commit({ pathParameters } = {}) {
    let url = `${process.env.VUE_APP_REAL_STATE_PROJECTS_BASE_URL}/%(id)s/actions/commit`;
    return axios({
      url: `${sprintf(url, pathParameters)}`,
      method: "post",
      headers: {
        "x-api-key": process.env.VUE_APP_API_KEY,
      },
    });
  }

  async restore({ pathParameters } = {}) {
    let url = `${process.env.VUE_APP_REAL_STATE_PROJECTS_BASE_URL}/deleted-projects/%(id)s/actions/restore`;
    return axios({
      url: `${sprintf(url, pathParameters)}`,
      method: "post",
      headers: {
        "x-api-key": process.env.VUE_APP_API_KEY,
      },
    });
  }

  async createFinancingOptions({ data } = {}) {
    let url = `${process.env.VUE_APP_REAL_STATE_PROJECTS_BASE_URL}/financing-options`;
    return axios({
      url: url,
      method: "post",
      data: data,
      headers: {
        "x-api-key": process.env.VUE_APP_API_KEY,
      },
    });
  }

  async retrieveFinancingOptions() {
    let url = `${process.env.VUE_APP_REAL_STATE_PROJECTS_BASE_URL}/financing-options`;
    return axios({
      url: `${sprintf(url)}`,
      method: "get",
    });
  }
}
