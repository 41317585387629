<template>
  <vx-card title="Opciones de financiamiento">
    <div class="flex justify-end mb-5">
      <vs-button
        v-if="$can('create', 'project')"
        size="small"
        color="success"
        type="border"
        icon-pack="feather"
        icon="icon-plus"
        @click="addFinancingOption()"
      >
        Agregar nueva opción financiera
      </vs-button>
    </div>

    <form data-vv-scope="financing-data" ref="form" @submit.prevent class="grid gap-8 md:grid-cols-2 grid-cols-1">
      <vx-card
        v-for="(option, index) in financingOptions"
        :beforeChange="validateFinancingData"
        :title="`${index + 1} - ${option.name}`"
      >
        <div>
          <div class="vx-col md:w-3/5 w-full">
            <label :for="`name${index}`">Nombre</label>
            <vs-input
              :id="`name${index}`"
              :name="`name-${index}`"
              v-model="option.name"
              class="w-full"
              v-validate="'required'"
              autocomplete="off"
            />
            <span class="text-danger">{{ errors.first(`financing-data.name-${index}`) }}</span>
            <br />
          </div>

          <div class="vx-col md:w-3/5 w-full">
            <label :for="`observations${index}`">Observación</label>
            <vs-textarea
              :id="`observations${index}`"
              :name="`observations-${index}`"
              v-model="option.observations"
              class="w-full"
              autocomplete="off"
              height="100"
            />
          </div>

          <div class="vx-col md:w-3/5 w-full">
            <label :for="`premiumIntervals${index}`">Porcentaje de primas disponibles</label>
            <vs-input
              icon-pack="feather"
              icon="icon-percent"
              icon-after="true"
              :id="`premiumIntervals${index}`"
              :name="`premium-intervals-${index}`"
              v-model="option.premium_intervals"
              class="w-full"
              v-validate="'premiumInterval'"
              autocomplete="off"
            />
            <span class="text-danger">
              {{ errors.first(`financing-data.premium-intervals-${index}`) }}
            </span>
            <br />
          </div>

          <div class="vx-row">
            <div class="vx-col w-full">
              <label :for="`termDataInterval${index}`">Plazos disponibles</label>
            </div>

            <div class="vx-col md:w-1/5 w-full md:pr-0">
              <vs-select
                :id="`term${index}`"
                :name="`term-${index}`"
                v-model="option.term_data.type"
                v-validate="'required'"
                class="w-full select-large"
              >
                <vs-select-item :key="false" value="months" :text="'Meses'" class="w-full" />
                <vs-select-item :key="true" value="years" :text="'Años'" class="w-full" />
              </vs-select>
              <span class="text-danger">{{ errors.first(`financing-data.term-${index}`) }}</span>
              <br />
            </div>

            <div class="vx-col md:w-2/5 w-full md:pr-0">
              <vs-input
                :id="`termDataInterval${index}`"
                :name="`term-data-interval-${index}`"
                v-model="option.term_data.intervals"
                class="w-full"
                v-validate="'termDataInterval'"
                autocomplete="off"
              />
              <span class="text-danger">{{ errors.first(`financing-data.term-data-interval-${index}`) }}</span>
              <br />
            </div>
          </div>

          <div class="vx-row">
            <vs-divider class="vx-col w-full mt-5" position="center" color="dark">Datos de la tasa</vs-divider>

            <div class="vx-col w-full mb-5 flex">
              <vs-switch v-model="option.hasConditions" @change="toggleCondition(option)" />
              <label class="pl-4">¿La tasa es condicional según el monto a financiar?</label>
            </div>

            <template v-if="!option.hasConditions">
              <div class="vx-col w-2/5">
                <label :for="`rate${index}`" class="w-full select-large">Tasa</label>
                <vs-input
                  icon-pack="feather"
                  icon="icon-percent"
                  icon-after="true"
                  :id="`rate${index}`"
                  :name="`rate-${index}`"
                  v-model="option.rate"
                  class="w-full"
                  v-validate="'required|decimal:2|min_value:1|max_value:100'"
                  autocomplete="off"
                />
                <span class="text-danger">{{ errors.first(`financing-data.rate-${index}`) }}</span>
                <br />
              </div>
            </template>

            <div v-else class="vx-col w-full pb-12">
              <div v-for="(rate, rateIndex) in option.rates_by_currency[0].rates" class="vx-col justify-end w-full">
                <div class="vx-row">
                  <div class="vx-col w-11/12">
                    <div class="vx-row">
                      <div class="vx-col w-1/3">
                        <label :for="`rate-${index}-${rateIndex}`">Tasa</label>
                        <vs-input
                          icon-pack="feather"
                          icon="icon-percent"
                          icon-after="true"
                          :id="`rate-${index}-${rateIndex}`"
                          :name="`rate-${index}-${rateIndex}`"
                          v-model="rate.rate"
                          class="w-full"
                          v-validate="
                            `required|decimal:2|min_value:0|max_value:100|uniqueRates:${ratesArray(rateIndex, option.rates_by_currency[0].rates)}`
                          "
                          autocomplete="off"
                        />
                        <span class="text-danger">{{ errors.first(`financing-data.rate-${index}-${rateIndex}`) }}</span>
                        <br />
                      </div>

                      <div class="vx-col w-1/3">
                        <label :for="`min-${index}-${rateIndex}`">Min</label>
                        <vs-input
                          :id="`min-${index}-${rateIndex}`"
                          :name="`min-${index}-${rateIndex}`"
                          v-model="rate.conditions['loan_amount'].min"
                          class="w-full"
                          v-validate="minConditions(rate)"
                          autocomplete="off"
                        />
                        <span class="text-danger">{{ errors.first(`financing-data.min-${index}-${rateIndex}`) }}</span>
                        <br />
                      </div>

                      <div class="vx-col w-1/3">
                        <label :for="`max-${index}-${rateIndex}`">Max</label>
                        <vs-input
                          :id="`max-${index}-${rateIndex}`"
                          :name="`max-${index}-${rateIndex}`"
                          v-model="rate.conditions['loan_amount'].max"
                          class="w-full"
                          v-validate="`decimal:2|min_value:${rate.conditions['loan_amount'].min}`"
                          autocomplete="off"
                        />
                        <span class="text-danger">{{ errors.first(`financing-data.max-${index}-${rateIndex}`) }}</span>
                        <br />
                      </div>
                    </div>
                  </div>

                  <div
                    v-if="option.rates_by_currency[0].rates.length > 1"
                    class="vx-col w-1/12 flex justify-start self-center pl-0"
                  >
                    <vs-button
                      size="medium"
                      color="danger"
                      type="border"
                      icon-pack="feather"
                      icon="icon-trash"
                      @click="removeRate(option.rates_by_currency[0].rates, rateIndex)"
                    ></vs-button>
                  </div>
                </div>
              </div>

              <div class="vx-col flex justify-end">
                <vs-button
                  size="small"
                  color="success"
                  type="border"
                  icon-pack="feather"
                  icon="icon-plus"
                  @click="addRate(option.rates_by_currency[0].rates)"
                >
                  Agregar condición
                </vs-button>
              </div>
            </div>
          </div>
        </div>

        <div slot="footer" class="absolute bottom-0 right-0 mt-6 pb-6 pr-6">
          <vs-row vs-justify="flex-end">
            <vs-button
              v-if="$can('create', 'project')"
              radius
              size="medium"
              color="danger"
              type="border"
              icon-pack="feather"
              icon="icon-trash"
              @click="removeFinancingOption(index)"
            ></vs-button>
          </vs-row>
        </div>
      </vx-card>
    </form>

    <div class="flex justify-end mt-5">
      <vs-button
        v-if="$can('create', 'project')"
        size="small"
        icon-pack="feather"
        icon="icon-save"
        @click="createOrUpdateFinancingOptions()"
        class="ml-4"
      >
        Guardar cambios
      </vs-button>
    </div>
  </vx-card>
</template>

<script>
  import RealStateProjectsClient from "@/utils/realStateProjectsClient";
  import Notifier from "@/utils/notification";
  import { cloneDeep } from "lodash";
  import { Validator } from "vee-validate";
  import { premiumInterval, termDataInterval, uniqueRates } from "@/utils/customValidations";

  const REAL_STATE_PROJECT_CLIENT = new RealStateProjectsClient();
  let notifier = new Notifier();

  Validator.localize({
    en: {
      messages: {
        required: "Este campo es requerido",
        decimal: (_, decimals) => `El campo debe ser numérico y puede contener hasta ${decimals} puntos decimales`,
        min_value: (_, min) => `El campo no debe ser menor a: ${min}`,
        max_value: (_, max) => `El campo no debe ser mayor a: ${max}`,
      },
    },
  });

  Validator.extend("premiumInterval", premiumInterval);
  Validator.extend("termDataInterval", termDataInterval);
  Validator.extend("uniqueRates", uniqueRates);

  export default {
    data() {
      return {
        financingOptions: [],
      };
    },

    mounted() {
      this.$vs.loading();

      if (this.$route.params.id !== undefined) {
        this.loadEntryData(this.$route.params.id).then(() => {
          this.$vs.loading.close();
        });
      } else {
        this.getFinancingOptions(false).then(() => {
          this.$vs.loading.close();
        });
      }
    },

    methods: {
      addFinancingOption() {
        this.financingOptions.push({
          name: "",
          slug: "",
          rate: 0,
          hasConditions: false,
          currency: "HNL",
          rates_by_currency: [
            {
              currency: "HNL",
              rates: [
                {
                  rate: 0,
                  conditions: {},
                },
              ],
            },
          ],
          premium_intervals: "10, 20, 30, 40, 50, 60, 70",
          term_data: {
            intervals: "5, 10, 15, 20, 25, 30",
            type: "years",
          },
        });

        var messageDisplay = this.$refs.form;

        setTimeout(() => {
          messageDisplay.scrollIntoView({ behavior: "smooth", block: "end" });
        }, 500);
      },

      addRate(rates) {
        rates.push({
          rate: 0,
          conditions: {
            loan_amount: {
              min: 0,
              max: null,
            },
          },
        });
      },

      buildData(financingOptions) {
        let data = [];

        for (const financingOption of financingOptions) {
          financingOption.premium_intervals = [
            ...new Set(financingOption.premium_intervals.split(",").map(Number)),
          ].sort((a, b) => a - b);
          financingOption.term_data.intervals = [
            ...new Set(financingOption.term_data.intervals.split(",").map(Number)),
          ].sort((a, b) => a - b);

          let rates_by_currency = {};

          if (financingOption.hasConditions) {
            for (const rateByCurrency of financingOption.rates_by_currency) {
              if (rates_by_currency[rateByCurrency.currency]) {
                rates_by_currency[rateByCurrency.currency].push(rateByCurrency.rates);
              } else {
                rates_by_currency[rateByCurrency.currency] = rateByCurrency.rates;
              }
            }
          } else {
            rates_by_currency["HNL"] = [
              {
                rate: Number(financingOption.rate),
                conditions: {},
              },
            ];
          }

          financingOption.rates_by_currency = rates_by_currency;

          data.push({
            name: financingOption.name,
            observations: financingOption.observations,
            premium_intervals: financingOption.premium_intervals,
            rates_by_currency: financingOption.rates_by_currency,
            term_data: financingOption.term_data,
          });
        }

        return data;
      },

      async createOrUpdateFinancingOptions() {
        this.validateFinancingData()
          .then(async (resp) => {
            if (resp === true) {
              this.$vs.loading();

              let data = { options: this.buildData(cloneDeep(this.financingOptions)) };

              await REAL_STATE_PROJECT_CLIENT.createFinancingOptions({
                data: data,
              })
                .then(() => {
                  this.$vs.loading.close();
                  notifier.notification("created");
                  this.getFinancingOptions();
                })
                .catch(() => {
                  this.$vs.loading.close();
                  notifier.alertMessage("error");
                });
            }
          })
          .catch(() => {
            return;
          });
      },

      async getFinancingOptions(showLoading = true) {
        if (showLoading) {
          this.$vs.loading();
        }

        await REAL_STATE_PROJECT_CLIENT.retrieveFinancingOptions()
          .then((response) => {
            if (showLoading) {
              this.$vs.loading.close();
            }
            this.financingOptions = response.data.options;

            this.prepareData(this.financingOptions);
          })
          .catch(() => {
            if (showLoading) {
              this.$vs.loading.close();
            }
            notifier.notification("error");
          });
      },

      minConditions(rate) {
        if (rate.conditions["loan_amount"].max) {
          return `required|decimal:2|min_value:0|max_value:${rate.conditions["loan_amount"].max}`;
        }

        return `required|decimal:2|min_value:0`;
      },

      prepareData() {
        for (const financingOption of this.financingOptions) {
          financingOption.premium_intervals = financingOption.premium_intervals.join(", ");
          financingOption.term_data.intervals = financingOption.term_data.intervals.join(", ");

          let hasConditions = false;

          let ratesByCurrency = [];

          for (const currency in financingOption.rates_by_currency) {
            hasConditions = financingOption.rates_by_currency[currency].some(
              (rate) => Object.keys(rate.conditions).length > 0,
            );

            ratesByCurrency.push({
              currency: currency,
              rates: financingOption.rates_by_currency[currency],
            });
          }

          this.$set(financingOption, "rates_by_currency", ratesByCurrency);
          this.$set(financingOption, "hasConditions", hasConditions);

          this.$set(financingOption, "rate", financingOption.rates_by_currency[0].rates[0].rate);
          this.$set(financingOption, "currency", financingOption.rates_by_currency[0].currency);
        }
      },

      ratesArray(index, ratesData) {
        let rates = ratesData.map((rate) => rate.rate);

        rates.splice(index, 1);

        return rates;
      },

      remove(financingOption, premium) {
        financingOption.premium_intervals.splice(financingOption.premium_intervals.indexOf(premium), 1);
      },

      removeFinancingOption(index) {
        this.financingOptions.splice(index, 1);
      },

      removeRate(rates, index) {
        rates.splice(index, 1);
      },

      termLabel(term) {
        const termsMapper = {
          years: "años",
          months: "meses",
        };

        return termsMapper[term];
      },

      toggleCondition(financingOption) {
        for (const rateByCurrency of financingOption.rates_by_currency) {
          for (const rate of rateByCurrency.rates) {
            if (!rate.conditions["loan_amount"]) {
              rate.conditions["loan_amount"] = {
                min: 0,
                max: null,
              };
            }
          }
        }
      },

      validateFinancingData() {
        return new Promise((resolve, reject) => {
          this.$validator.validateAll("financing-data").then((result) => {
            if (result) {
              resolve(true);
            } else {
              reject("Please fix invalid fields");
            }
          });
        });
      },
    },
  };
</script>

<style>
  .vs-con-loading__container {
    position: static !important;
  }
</style>
